@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Digitizer';
  src: url('./assets/fonts/digitizer.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Press Start 2P', 'Digitizer', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #373737;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

.root {
  width: 100%;
}

@layer utilities {
  .text-hover {
    color: #FFC700;
    text-shadow:
      -2px -2px 0 #000,
      2px -2px 0 #000,
      -2px 2px 0 #000,
      2px 2px 0 #000,
      0px 4px 4px rgba(0, 0, 0, 0.70);
  }

  .font-digit {
    font-family: 'Digitizer', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif !important;
  }

  .animation {
    transition: 0.2s all ease-in-out;
  }

  .text-stroke {
    text-shadow:
      -2px -2px 0 #000,
      2px -2px 0 #000,
      -2px 2px 0 #000,
      2px 2px 0 #000,
      0px 4px 4px rgba(0, 0, 0, 1);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* For Webkit (Chrome, Safari, etc.) */
::-webkit-scrollbar {
  width: 6px;
  /* width of the entire scrollbar */
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #00000000;
  /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: #ffffff12;
  /* color of the scroll thumb */
  border-radius: 16px;
  /* roundness of the scroll thumb */
  border: 3px solid #00000000;
  /* creates padding around scroll thumb */
}

/* For Firefox */
* {
  scrollbar-width: thin;
  /* "auto" or "thin" */
  scrollbar-color: #ffffff12 #00000000;
  /* thumb and track color */
}

/* Hover effect for webkit scrollbar thumb */
::-webkit-scrollbar-thumb:hover {
  background-color: #ffffff12;
}